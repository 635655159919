.iframe {
    /* Navigation widths + border */
    --chat-iframe-primary-navigation-width: calc(48px + 1px) ;
    --chat-iframe-primary-navigation-width-open: calc(220px + 1px); 

    position: fixed;
    right: 0;
    bottom: 0;
    z-index: calc(var(--cui-z-index-navigation) + 1);
    max-width: 100%;
    max-height: calc(100vh - var(--top-navigation-height));
}

.iframe.fullScreen:not(.minimized) {
    right: 0;
    max-width: 100%;
    max-height: calc(100vh - var(--top-navigation-height));
}

@media (min-width: 1280px) {
  .iframe.fullScreen:not(.minimized) {
        max-width: calc(100% - var(--chat-iframe-primary-navigation-width));
    }
}

@media (min-width: 1900px) {
  .iframe.fullScreen:not(.minimized) {
    max-width: calc(100% - var(--chat-iframe-primary-navigation-width-open));
  }
}

@media (max-width: 959px) {
  .iframe.fullScreen:not(.minimized) {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
  }
}